import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { CustomerInformationService } from "common/services/customer-information/CustomerInformationService";
import { InputField, ToggleButton,Loader } from "components/Index";
import {
    CustomerInformationSchema,
    CustomerInformationSchemaType,
} from "./CustomerInformationSchema";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { ParserHelper } from "helpers/Index";
import { CustomerScheduleAppointmentService } from "common/services/customer-schedule-appointment/CustomerScheduleAppointmentService";
import { Button } from "@mui/material";
import { HttpResponseType } from "models/IHCResponseModel";
import { customerInformation } from "store/CustomerStore";
import { IUserSession } from "models/login/IAuthResponse";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';

export const CustomerInformation = () => {
    const customerInformationForm = useForm<CustomerInformationSchemaType>({
        resolver: zodResolver(CustomerInformationSchema),
        mode: "onChange",
    });

    const [isLoading, setIsLoading] = useState(false);
    const customerSession = useAuthUser<IUserSession>();
    const navigate = useNavigate();
    const dispatch = useDispatch();
   
    useEffect(() => {
        (async () => {
            await getCustomerInformation();
        })().catch((error) => {
            throw error;
        });
    }, []);

    const getCustomerInformation = async () => {
        setIsLoading(true);

        const customerAppointmentSchedule = await CustomerScheduleAppointmentService.getCustomerAppointmentScheduleAsync(customerSession?.facilityId!);

        if (customerAppointmentSchedule.startTime !== null) {
            navigate(`/customer-appointment-confirmation`);
            return;
        }

        await CustomerInformationService.getCustomerInformationByReferenceCodeAsync(
            customerSession?.referenceCode!
        ).then(
            (response) => {
                setIsLoading(false);
                customerInformationForm.reset(response);
            },
            (error) => {
                toast.error(error.message, { theme: "colored" });
                setIsLoading(false);
            }
        );
    };

    const submitHandler = async () => {
        setIsLoading(true);
        const model = customerInformationForm.getValues() as CustomerInformationSchemaType;
        await CustomerInformationService.updateCustomerInformationAsync(model).then(async (response) => {
            setIsLoading(false)
            if (response.status === HttpResponseType.Failed) {
                toast(response.message);
                return;
            }
            dispatch(customerInformation(model));
            toast(`Customer Information updated.`);
            navigate(`/customer-schedule-appointment`);
        }, error => {
            toast(error.message);
            setIsLoading(false);
        });
    }

    return (
        <Fragment>
            <Loader isLoading={isLoading}>
                <form onSubmit={customerInformationForm.handleSubmit(submitHandler)}>
                    <div className="customer-information-screen" style={{ display: customerInformationForm.getValues('referenceCode') ? '' : 'none' }}>
                        <div className="container">
                            <h1 className="title-one">Schedule your appointment</h1>
                            <h5>
                                To schedule your appointment please provide the following
                                information{" "}
                            </h5>
                            <div className="form-box">
                                <div className="row customer-information-form-container">
                                    <div className="col-sm-6">
                                        <InputField
                                            controlkey="serviceContactName"
                                            label=""
                                            type="text"
                                            placeholder="Service Contact Name*"
                                            controlregister={customerInformationForm.register(
                                                "serviceContactName"
                                            )}
                                            error={
                                                customerInformationForm.formState.errors.serviceContactName
                                                    ?.message as string
                                            }
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <InputField
                                            controlkey="primaryPhone"
                                            label=""
                                            type="text"
                                            isrequired={true}
                                            placeholder="Primary Phone*"
                                            controlregister={customerInformationForm.register("primaryPhone", {
                                                onChange: (e) => customerInformationForm.setValue("primaryPhone", ParserHelper.parsePhone(e.target.value, 10)!, { shouldDirty: true, shouldValidate: true })
                                            })}
                                            error={
                                                customerInformationForm.formState.errors.primaryPhone
                                                    ?.message as string
                                            }
                                        />
                                    </div>
                                    <div className="col-sm-12 email-wrapper">
                                        <InputField
                                            controlkey="email"
                                            label=""
                                            type="email"
                                            placeholder="Email Address*"

                                            controlregister={customerInformationForm.register(
                                                "email"
                                            )}
                                            error={
                                                customerInformationForm.formState.errors.email
                                                    ?.message as string
                                            }
                                        />
                                        <p className="email-reminder-description">
                                            Reminders and installation updates are provided via
                                            email
                                        </p>
                                    </div>
                                    <div className="col-sm-6 tl">
                                        <ToggleButton
                                            controlkey="canReceiveTextMessage"
                                            label="Can you receive text messages?"
                                            labelClassName="toggle-label"
                                            form={customerInformationForm}
                                            controlregister={customerInformationForm.register(
                                                "canReceiveTextMessage"
                                            )}
                                        ></ToggleButton>
                                    </div>
                                    <div className="col-sm-6 tl">
                                        <ToggleButton
                                            controlkey="isFinishedBasement"
                                            labelClassName="toggle-label"
                                            label="Do you have finished basement?"
                                            form={customerInformationForm}
                                            controlregister={customerInformationForm.register(
                                                "isFinishedBasement"
                                            )}
                                        ></ToggleButton>
                                    </div>

                                    <div className="col-sm-6 tl">
                                        <ToggleButton
                                            controlkey="isEasyAccessToWaterMeter"
                                            labelClassName="toggle-label"
                                            label="Is there easy access to you water meter"
                                            form={customerInformationForm}
                                            controlregister={customerInformationForm.register(
                                                "isEasyAccessToWaterMeter"
                                            )}
                                        ></ToggleButton>
                                    </div>
                                    <div className="col-sm-6 tl">
                                        <ToggleButton
                                            controlkey="isLocationSingleFamilyHome"
                                            labelClassName="toggle-label"
                                            label="Is this location a single family home?"
                                            form={customerInformationForm}
                                            controlregister={customerInformationForm.register(
                                                "isLocationSingleFamilyHome"
                                            )}
                                        ></ToggleButton>
                                    </div>
                                </div>

                                <Button
                                    className="primary-contained-button"
                                    type="submit"
                                    disabled={
                                        !customerInformationForm.formState.isValid ||
                                        customerInformationForm.formState.isSubmitting
                                    }
                                    variant="contained"
                                >
                                    Next
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            </Loader>
        </Fragment>
    );
};
