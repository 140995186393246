import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import storage from 'common/utils/storage';
import { logout } from 'store/AuthStore';
import { AppFunctions } from "helpers/Index";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";

const ProtectedRoute = (props:any) => {
    const isAuthenticated = useIsAuthenticated();  
    const signOut = useSignOut();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [isLoggedIn, setIsLoggedIn] = useState(false);

     const validateLoggedInUser = () => {
        if (!isAuthenticated) return;

        const loggedInDateTime = storage.getTokenExpirationTime();
        if (loggedInDateTime.isSameOrAfter(AppFunctions.CurrentDateTimeInMoment())) return;

        signOut();
        dispatch(logout());
        return navigate("/login");
    };

    validateLoggedInUser();

    const checkUserToken = () => {
          if (!isAuthenticated)  {
            setIsLoggedIn(false);
            return navigate('/login');
        }
        setIsLoggedIn(true);
    }

    useEffect(() => {
        checkUserToken();
    }, [isLoggedIn]);

    return (
        <React.Fragment>
            {
                isLoggedIn ? props.children : null
            }
        </React.Fragment>
    );
}

export default ProtectedRoute;