import { SetClientBaseMicroserviceURL } from "config";
import { CustomerInformationSchemaType } from "pages/customer-information/CustomerInformationSchema";
import { axios } from "common/utils/Index";
import { IHCResponseModel } from "models/IHCResponseModel";

const getCustomerInformationByReferenceCodeAsync = async (referenceCode: string):
    Promise<CustomerInformationSchemaType> => {
    SetClientBaseMicroserviceURL();
    return await axios.post(`api/CustomerPortal/getcustomerinformation/`, {
        referenceCode
    }).catch((error: any) => {
        return error;
    });
}

const updateCustomerInformationAsync = async (model: CustomerInformationSchemaType): Promise<IHCResponseModel> => {
    SetClientBaseMicroserviceURL();
    return await axios.post(`api/CustomerPortal/updatecustomerinformation/`, model);
}

export const CustomerInformationService
    = {
    getCustomerInformationByReferenceCodeAsync: getCustomerInformationByReferenceCodeAsync,
    updateCustomerInformationAsync: updateCustomerInformationAsync
}