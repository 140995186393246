import { Constants } from "constants/Constants";
import { IControlProps } from "models/IControlProps";
import { Fragment, useEffect, useState } from "react";

type PartitionedInputFieldProps = IControlProps &
{
    className: string,
    maxLength?: number
}

const PartitionedInputField = ((props: PartitionedInputFieldProps) => {
    const [isRequired, setIsRequired] = useState<boolean>(props.isrequired ?? false);
    useEffect(() => {
        setIsRequired(props.isrequired ?? false)
    }, [props.isrequired])

    return (
        <Fragment>
            <div className={props.type !== Constants.ControlTypeHidden ? `form-group` : ``}>
                {props.type !== Constants.ControlTypeHidden && <label htmlFor={props.controlkey}>
                    <span>{props.label}</span>
                    {isRequired &&
                        <i className={`required-icon`}></i>
                    }
                </label>}
                <div>
                    <input
                        className={props.className}
                        id={props.controlkey}
                        disabled={props.readOnly}
                        maxLength={props.maxLength}
                        type={props.type || Constants.ControlTypeText}
                        placeholder={props.placeholder}
                        readOnly={props.readOnly ?? false}
                        value={props.value}
                        {...(props.controlregister ?? {})}
                    />
                    {
                        props.error ? (<div className="error">{props.error}</div>) : null
                    }
                </div>
            </div>
        </Fragment>
    );
});
export default PartitionedInputField;