import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { AuthStoreSlice } from "store/AuthStore";
import { CustomerStoreSlice } from "store/CustomerStore";
import {persistStore, persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
 key: "root",
 storage
};

const allReducers = combineReducers({
    login: AuthStoreSlice.reducer,
    customer: CustomerStoreSlice.reducer,
})

const persistedReducer = persistReducer(persistConfig, allReducers);


export const store = configureStore({
    reducer: persistedReducer
});

export default store;
export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

