import { Fragment } from "react";

const LoginHeader = () => {
  const logo = "/LogoHydroCorp.png";
  return (
    <Fragment>
      <nav className="login-navbar">
        <img src={logo} className="HYDROCORP LOGO" alt='Hydrocorp Logo'></img>
      </nav>
    </Fragment>
  );
};

export default LoginHeader;
