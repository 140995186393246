export interface IAuthResponse {
    accessToken: string | null;
    refreshToken: string | null;
    expiersIn: number | null;
    refreshTokenExpiersIn: number | null;
    isPasswordExpired: boolean | null;
    message: string | null;
    statusCode: number | null;
}

export interface IUserSession {
    referenceCode: string | null;
    facilityId: string | null;    
    facilityName: string | null;    
    email: string | null;
    tenantId: string | null;
    projectId: string | null;

}

export const initialState: IUserSession = {
    referenceCode: null,
    facilityId: null,
    facilityName: null,
    email: null,
    tenantId: null,
    projectId: null,
};
