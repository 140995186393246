import { Outlet } from "react-router-dom";
import LoginHeader from "layouts/LoginHeader";

const LoginLayout = () => {
  return (
    <div className="login-container">
      <LoginHeader></LoginHeader>
      <Outlet />
    </div>
  );
};

export default LoginLayout;
