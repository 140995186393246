import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Fragment, useState } from 'react';
import { Moment } from 'moment';
import { PickersDay, PickersDayProps, pickersDayClasses } from '@mui/x-date-pickers';
import { styled } from '@mui/material/styles';
import { PickerSelectionState } from '@mui/x-date-pickers/internals';
import moment from 'moment';

export type IDateCalenderControlProps = {
    minDate?: any,
    maxDate?: any,
    views: Array<'year' | 'month' | 'day'>,
    className?: string
    onChange?: (value?: Moment, selectionState?: PickerSelectionState) => void;
    dates: Moment[]
}

type CustomPickerDayProps = PickersDayProps<Moment> & {
    isSelected: boolean,
}

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) =>
        prop !== 'isSelected'
})<CustomPickerDayProps>(({ theme, isSelected }) => ({

    ...(isSelected && {
        borderRadius: 500,

        backgroundColor: "#cee7f4",
        borderColor: "#cee7f4",
        color: "#000000",


    })

})) as React.ComponentType<CustomPickerDayProps>;

function Day(props: PickersDayProps<Moment> & { highlightedDays?: Moment[] }) {
    const { highlightedDays = [], day, ...other } = props;
    const isSelected =
        highlightedDays.findIndex((date) => date.isSame(day)) >= 0;

    if (highlightedDays == null) {
        return <PickersDay day={day} {...other} />;
    }

    return (
        <CustomPickersDay
            {...other}
            sx={{
                [`&&.${pickersDayClasses.selected}`]: {
                    backgroundColor: "#4ec5bc",
                    color: "#000000",
                }
            }}
            day={day}
            isSelected={isSelected}
        />
    );
}

const DateCalendarField = (props: IDateCalenderControlProps) => {

    const onChange = (value?: Moment, selectionState?: PickerSelectionState) => {
        props.onChange?.(value!, selectionState);
    }

    const [highlightedDays] = useState<Moment[]>(props.dates);

    return (
        <Fragment>

            <LocalizationProvider dateAdapter={AdapterMoment} >
                <DateCalendar
                    minDate={props.minDate ?? moment()}
                    views={props.views!}
                    openTo="month"
                    className={props.className}
                    onChange={(value, selectionState) => onChange(value!, selectionState)}
                    slots={{ day: Day }}
                    slotProps={{
                        day: {

                            highlightedDays
                        } as any,
                    }}
                />
            </LocalizationProvider>

        </Fragment>
    )
}

export default DateCalendarField;


