import React from "react";

type Props = {
    children: string | JSX.Element | JSX.Element[];
    isLoading: boolean;
    areaLoader?: boolean;
    isBackgroundChange?: boolean;
};

const Loader = (props: Props) => {
    const loadingClass = props.areaLoader ? `loading transparent` : "loading";
    return (
        <React.Fragment>
            {props.children}
            <div className={`${props.isLoading ? loadingClass : ""}`} style={{
                backgroundColor: props.isBackgroundChange ? `transparent` : `rgba(255, 255, 255, 0.5)`,
                display: props.isLoading ? '' : 'none'
            }}>
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Loader;