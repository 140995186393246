import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { LicenseInfo } from "@mui/x-license-pro";
import PageNotFound from 'components/PageNotFound';
import HomeLayout from 'layouts/HomeLayout';
import LoginLayout from "layouts/LoginLayout";
import { Login } from 'pages/login/Login';
import { CustomRoutes } from "pages/CustomRoutes";
import AuthProvider, { AuthError } from 'react-auth-kit';
import createStore from "react-auth-kit/createStore";
import {storagePrefix} from "common/utils/storage";

LicenseInfo.setLicenseKey(
  "db3778bfd01704f00cd67f803e1af331Tz04ODM1NixFPTE3NDQ1NzkzMzUwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <PageNotFound />,
    element: <LoginLayout />,
    children: [
      { path: "/", element: <Login /> },
      { path: "/login", element: <Login /> }
    ],
  }
  ,
  {
    path: "/",
    errorElement: <PageNotFound />,
    element: <HomeLayout />,
    children: [
      ...CustomRoutes
    ],
  }
]);

const store = createStore({
    authName: storagePrefix,
    authType: 'localstorage',
    cookieDomain: window.location.hostname,
    cookieSecure: window.location.protocol === 'http:',
    // refresh: createRefresh({
    //     interval: (parseFloat(process.env.REACT_APP_JWT_TOKEN_EXPIRES_IN!) - 2),
    //     refreshApiCallback: async (param: any): Promise<any> => {
    //         try {
    //             console.log("Refreshing")
    //             const userToken = param?.authToken;
    //             if (!userToken || userToken === "undefined") return;

    //             var response = await refreshToken();
    //             const decodedToken = storage.decodeToken(response.accessToken!);
    //             var result = {
    //                 isSuccess: true,
    //                 newAuthToken: response.accessToken,
    //                 newAuthTokenType:'Bearer',
    //                 newRefreshToken: response.refreshToken,                   
    //                 newAuthUserState: {
    //                     ...decodedToken
    //                 }
    //             }
    //             return result;
    //         }
    //         catch (error) {
    //             console.error(error);
    //             throw new AuthError(
    //                 'This appication is using refresh token feature.' +
    //                 ' So `refreshApi` occouring issue.',
    //             );
    //         }
    //     }
    // }),
    debug: true
});


export const App = () => {
    return <AuthProvider store={store}>
        <RouterProvider router={router} />
    </AuthProvider>
};


export default App;
