import storage from "common/utils/storage";
import { initialState, IUserSession } from "models/login/IAuthResponse";
import { createSlice } from "@reduxjs/toolkit";

export const AuthStoreSlice = createSlice({
    name: "login",
    initialState: initialState,
    reducers: {
        userLoginInfo: (state: IUserSession, action) => {
            const authToken = action.payload.accessToken ?? action.payload;            
            var userdata = storage.decodeToken(authToken);            
            state.facilityName = userdata.facilityName;
            state.email = userdata.email;
            state.referenceCode = userdata.referenceCode;
            state.tenantId = userdata.tenantId;
            state.facilityId = userdata.facilityId;
            state.projectId = userdata.projectId;            
        },
        logout: (state: IUserSession) => {
            state = { ...initialState };
           storage.clearUserSession();
        }
    },
});

export const { userLoginInfo, logout } = AuthStoreSlice.actions;