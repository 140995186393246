import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "store/AuthStore";
import { Button } from "@mui/material";
import LogoutSharpIcon from "@mui/icons-material/LogoutSharp";
import useSignOut from 'react-auth-kit/hooks/useSignOut';

const HomeNavbar = () => {
  const logo = "/LogoHydroCorp.png";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const signOut = useSignOut();
  const onLogoutHandler = () => {
    signOut();
    dispatch(logout());
    navigate("/login");
  };

  return (
    <React.Fragment>
      <nav className="page-navbar">
        <img src={logo} className="brand-logo" alt='Brand Logo' />
        <Button
          className="primary-contained-button logout-btn"
          onClick={onLogoutHandler}
          variant="contained"
        >
          Logout <LogoutSharpIcon />
        </Button>
      </nav>
    </React.Fragment>
  );
};

export default HomeNavbar;
