export interface IHttpException {
    success: boolean;
    statusCode: number;
    error: string;
    code: string;
    message: string;
    details?: any;
}

export class HttpStatusCode {
    public static get Unauthorized(): number { return 403; }
}