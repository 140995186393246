import React from "react";
import { Outlet } from "react-router-dom";
import HomeNavbar from "layouts/HomeNavbar";

const HomeLayout = () => {
  return (
    <React.Fragment>
      <div className="page-layout">
      <HomeNavbar />
      <Outlet />
      </div>
    
    </React.Fragment>
  );
};

export default HomeLayout;