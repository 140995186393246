import ProtectedRoute from "common/utils/ProtectedRoute"
import { CustomerInformation } from "pages/customer-information/CustomerInformation"
import { CustomerAppointmentSchedule } from "pages/customer-schedule-appointment/CustomerAppointmentSchedule"
import { CustomerAppointmentConfirmation } from "pages/CustomerAppointmentConfirmation"
import { CustomerArrivalInformation } from "pages/CustomerArrivalInformation"

const routes = [
  {
    path: "customer-information",
    element: (
      <ProtectedRoute>
        <CustomerInformation />
      </ProtectedRoute>
    )
  },
  {
    path: "customer-schedule-appointment",
    element: (
      <ProtectedRoute>
        <CustomerAppointmentSchedule />
      </ProtectedRoute>
    )
  }
  ,
  {
    path: "customer-arrival-information/:windowid",
    element: (
      <ProtectedRoute>
        <CustomerArrivalInformation />
      </ProtectedRoute>
    )
  }
  ,
  {
    path: "customer-appointment-confirmation/:windowstarttime/:windowendtime",
    element: (
      <ProtectedRoute>
        <CustomerAppointmentConfirmation />
      </ProtectedRoute>
    )
  },
  {
    path: "customer-appointment-confirmation",
    element: (
      <ProtectedRoute>
        <CustomerAppointmentConfirmation />
      </ProtectedRoute>
    )
  }
]

export const CustomRoutes = [
  ...routes
]