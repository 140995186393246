import Axios, { AxiosError, InternalAxiosRequestConfig } from "axios";
import storage from "common/utils/storage";
import { HttpStatusCode } from "models/exceptions/IHttpException";
import { AuthError } from "react-auth-kit";

function authRequestInterceptor(config: InternalAxiosRequestConfig) {
    config.headers = config.headers ?? {};
    const token = storage.getToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers.Accept = "application/json";
    return config;
}

const axios = Axios.create({
    baseURL: process.env.REACT_APP_USER_API_URL,
});

axios.interceptors.request.use(authRequestInterceptor);

axios.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        const err = error as Error | AxiosError;
        if (error?.response?.status === HttpStatusCode.Unauthorized) {            
            throw new AuthError(
                'Getting Unauthorized error.' +
                ' So occouring issue.' + error,
            );
        }
        if (!Axios.isAxiosError(err)) {
            return err;
        }

        const data = error.response?.data ?? error.message;
        throw new AuthError('Appication getting ' + data);
    }
);

export default axios;