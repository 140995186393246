import moment, { Moment } from "moment";

class AppFunctions {

    public static IsNullOrUndefined(value: any | undefined): boolean {
        return (value === undefined || value === null || value.length === 0 || value === '')
    }

    public static IsNullOrWhiteSpace(value: string | undefined | '' | null): boolean {
        return (value === undefined || value === null || value.toString().trim() === '')
    }
    public static ConvertToUniversalTime(value: any): Moment {
        const timezoneOffset = new Date().getTimezoneOffset() / 60;
        return moment(value).utc().add(-timezoneOffset, 'hours');
    }
    public static CurrentDateTimeInMoment(): Moment {
        return moment(new Date())
    }
}

export default AppFunctions;
