import { SetClientBaseMicroserviceURL } from "../../../config";
import { IAuthResponse } from "../../../models/login/IAuthResponse";
import { axios } from "common/utils/Index";

const loginAsync = async (referenceCode: string): Promise<IAuthResponse> => {
    SetClientBaseMicroserviceURL();
    return await axios.post(`api/CustomerPortal/customerlogin`, {
        referenceCode
    });
}

export const AuthService
    = {
    loginAsync: loginAsync
}