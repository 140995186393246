import moment from "moment";
import { IUserSession } from "models/login/IAuthResponse";
import jwt_decode from 'jwt-decode';
export const storagePrefix = '_customer_portal_auth';

export class storageKey {
    public static get AuthToken(): string { return storagePrefix; }    
    public static get AuthState(): string { return `${storagePrefix}_state`; }
    public static get AuthTokenExpiration(): string { return `${storagePrefix}_token_expiration`; }
    public static get AuthRefreshTokenExpiration(): string { return `${storagePrefix}_refresh_token_expiration`; }       
}

const storage = {

    getToken: () => {
        return window.localStorage.getItem(storageKey.AuthToken) as string;
    },
    
    setToken: (token: string) => {
         window.localStorage.setItem(storageKey.AuthToken, token);
    },
    getUserSession: (): IUserSession => {
        return JSON.parse(window.localStorage.getItem(storageKey.AuthState) as string) as IUserSession;
    },
    getRefreshTokenExpirationTime: () => {
        const expireRefreshTokenISODateTime = window.localStorage.getItem(storageKey.AuthRefreshTokenExpiration) as string;
        return moment(expireRefreshTokenISODateTime);
    },
    setRefreshTokenExpirationTime: (expirenInSeconds: number) => {
        const expireRefreshTokenDateTime = moment(new Date()).add(expirenInSeconds, 'seconds').toISOString();
        window.localStorage.setItem(storageKey.AuthRefreshTokenExpiration, expireRefreshTokenDateTime);
    },
    getTokenExpirationTime: () => {
        const expireTokenISODateTime = window.localStorage.getItem(storageKey.AuthTokenExpiration) as string;
        return moment(expireTokenISODateTime);
    },
    setTokenExpirationTime: (expirenInSeconds: number) => {
        const expireTokenDateTime = moment(new Date()).add(expirenInSeconds, 'seconds').toISOString();
        window.localStorage.setItem(storageKey.AuthTokenExpiration, expireTokenDateTime);
    },
    clearUserSession: () => {
        window.localStorage.removeItem(storageKey.AuthTokenExpiration);
        window.localStorage.removeItem(storageKey.AuthRefreshTokenExpiration);
        window.sessionStorage.removeItem('persist:root')
        window.localStorage.removeItem('persist:root')
    },
    decodeToken: (token: string): IUserSession => {
        type CustomerLoginData = {
            ReferenceCode: string | null;
            FacilityId: string | null;
            FacilityName: string | null;
            Email: string | null;
            TenantId: string;
            ProjectId: string;
        }
        const getUserData = (result: CustomerLoginData): IUserSession => {
           return { 
              referenceCode: result.ReferenceCode,
              facilityId: result.FacilityId,
              facilityName: result.FacilityName,
              email: result.Email,
              tenantId: result.TenantId,
              projectId: result.ProjectId
            };
        }
        let customerData = {} as CustomerLoginData;
        if (token) {
            var decoded = jwt_decode<object>(token);
            let tokenJSON = JSON.parse(JSON.stringify(decoded));
            for (let prop in tokenJSON) {
                if (prop === 'customerInformation') {
                    customerData = tokenJSON[prop];
                }
            }
        }
        return getUserData(customerData);
    }
};

export default storage;
